import { useAuth0 } from "@auth0/auth0-react";
import aos from "aos";
import "aos/dist/aos.css";
import { ReactElement, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DotsMenu from "./components/common/DotsMenu";
import Loader from "./components/common/Loader";
import Sidebar from "./components/common/Sidebar";
import Accounts from "./pages/Accounts";
import Authenticate from "./pages/Authenticate";
import Coworking from "./pages/Coworking";
import Directory from "./pages/Directory";
import DirectoryCrafter from "./pages/DirectoryCrafter";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Profile from "./pages/Profile";
import ProfilePictureUploader from "./pages/ProfilePictureUploader";
import ReleaseNotes from "./pages/ReleaseNotes";
import { DirectoryProvider } from "./providers/DirectoryProvider";
import { DoorsProvider } from "./providers/DoorsProvider";
import EventsProvider from "./providers/EventsProvider";
import HttpProvider from "./providers/HttpProvider";
import { ProfileProvider } from "./providers/ProfileProvider";

const App = (): ReactElement => {
  const { isAuthenticated, isLoading } = useAuth0();
  useEffect(() => {
    aos.init({
      once: true,
    });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Authenticate />;
  }

  return (
    <BrowserRouter>
      <HttpProvider>
        <ProfileProvider>
          <DoorsProvider>
            <DirectoryProvider>
              <EventsProvider>
                <Sidebar />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/coworking" element={<Coworking />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route
                    path="/profile/profilePicture"
                    element={<ProfilePictureUploader />}
                  />
                  <Route path="/directory" element={<Directory />} />
                  <Route
                    path="/directory/:email"
                    element={<DirectoryCrafter />}
                  />
                  <Route
                    path="/directory/:email/profilePicture"
                    element={<ProfilePictureUploader />}
                  />
                  <Route path="/accounts" element={<Accounts />} />
                  <Route path="/release-notes" element={<ReleaseNotes />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <DotsMenu />
              </EventsProvider>
            </DirectoryProvider>
          </DoorsProvider>
        </ProfileProvider>
      </HttpProvider>
    </BrowserRouter>
  );
};

export default App;
