import { IconListDetails } from "@tabler/icons-react";
import { ReactElement, useEffect, useState } from "react";
import Blur from "../components/common/Blur";
import Loader from "../components/common/Loader";
import Crafter from "../components/directory/Crafter";
import SearchBar from "../components/directory/SearchBar";
import { displayedTabs } from "../environnment";
import BlockLayer from "../layers/BlockLayer";
import FlexboxLayer from "../layers/FlexboxLayer";
import PageLayer from "../layers/PageLayer";
import { useDirectory } from "../providers/DirectoryProvider";
import { useProfile } from "../providers/ProfileProvider";
import { filterAllowedTabs } from "../shared/others/filterAllowedTabs";
import { Profile } from "../types/profile";

const Directory = (): ReactElement => {
  const [isDisabled, setIsDisabled] = useState<boolean | undefined>(undefined);
  const [filteredCrafters, setFilteredCrafters] = useState<Profile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { profile, roles } = useProfile();
  const { crafters } = useDirectory();

  useEffect(() => {
    setIsDisabled(
      !filterAllowedTabs(roles, displayedTabs).some(
        (tab) => tab.path === "/directory",
      ),
    );
  }, [roles]);

  useEffect(() => {
    if (crafters !== null) {
      setFilteredCrafters(crafters);
      setIsLoading(false);
    }
  }, [crafters]);

  const handleSearchChange = (search: string): void => {
    let updatedFilteredCrafters: Profile[];

    if (search.length === 0) {
      updatedFilteredCrafters = crafters ?? [];
    } else {
      const normalizedSearch = search.toLowerCase();

      updatedFilteredCrafters =
        crafters?.filter((crafter: Profile) => {
          const { firstname, lastname } = crafter;
          return (
            firstname?.toLowerCase().includes(normalizedSearch) ||
            lastname?.toLowerCase().includes(normalizedSearch)
          );
        }) ?? [];
    }

    setFilteredCrafters(updatedFilteredCrafters);
  };

  if (isDisabled === undefined) {
    return <Loader />;
  }

  return (
    <>
      {!isDisabled && isLoading && <Loader />}
      <PageLayer>
        <header>
          <div className="icon">
            <IconListDetails size={55} />
          </div>
          <div className="text">
            <h1 className="page-title">Annuaire</h1>
            <span className="crafters-count">
              {filteredCrafters.length} crafter
              {filteredCrafters.length > 1 ? "s" : ""}
            </span>
          </div>
        </header>
        <BlockLayer>
          {!isDisabled && <SearchBar onChange={handleSearchChange} />}
          <FlexboxLayer>
            {isDisabled && (
              <Blur text="Vous avez besoin de l'abonnement BizDev pour voir cette fonctionnalité" />
            )}
            {filteredCrafters.map((crafter: Profile, index: number) => {
              const isCurrentProfile: boolean =
                crafter.email === profile?.email;
              return (
                <Crafter
                  crafter={isCurrentProfile ? profile : crafter}
                  canEdit={
                    roles.some((r) => r === "admin") &&
                    crafter?.email !== profile?.email
                  }
                  key={index}
                />
              );
            })}
          </FlexboxLayer>
        </BlockLayer>
      </PageLayer>
    </>
  );
};

export default Directory;
