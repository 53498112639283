import { IconHome } from "@tabler/icons-react";
import { ReactElement, useEffect, useState } from "react";
import Card from "../components/common/Card";
import { Date as DateTitle } from "../components/common/Date";
import Loader from "../components/common/Loader";
import Toggleable from "../components/common/Toggleable";
import BlockLayer from "../layers/BlockLayer";
import PageLayer from "../layers/PageLayer";
import { useDirectory } from "../providers/DirectoryProvider";
import { useEvents } from "../providers/EventsProvider";
import { useProfile } from "../providers/ProfileProvider";
import { wishBirthdays } from "../shared/directory/wishBirthdays";
import { differenceFromNowInDays } from "../shared/others/differenceFromNowInDays";
import { formatDate } from "../shared/others/formatDate";
import { Event } from "../types/event";

const Home = (): ReactElement => {
  const [openToggleable, setOpenToggleable] = useState<string>();
  const { birthdays } = useDirectory();
  const { events } = useEvents();
  const { profile } = useProfile();

  useEffect(() => {
    wishBirthdays(birthdays, profile);
  }, [birthdays, profile]);

  if (!profile) {
    return <Loader />;
  }

  return (
    <PageLayer>
      <header>
        <div className="icon">
          <IconHome size={55} />
        </div>
        <div className="text">
          <h1 className="page-title">Bonjour {profile?.firstname}</h1>
          <DateTitle />
        </div>
      </header>
      <BlockLayer>
        <Card customClass="events">
          <h1>Évènements à venir</h1>
          {events.length ? (
            events.map((event: Event, key: any) => (
              <Toggleable
                key={key}
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  border: "solid 1px #28282827",
                  fontSize: "15px",
                  overflow: "hidden",
                }}
                fadeEffect={false}
                id={event.id}
                label={
                  event.summary +
                  ` (${differenceFromNowInDays(new Date(event.start.dateTime).getTime())})`
                }
                setWichIsOpen={() =>
                  setOpenToggleable(
                    openToggleable === event.id ? undefined : event.id,
                  )
                }
                openToggleableId={openToggleable}
              >
                <header>
                  <h3>{event.summary}</h3>
                  <span className="date">
                    {formatDate(new Date(event.start.dateTime).getTime())}{" "}
                  </span>
                </header>
                <p
                  style={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{ __html: event.description }}
                />
              </Toggleable>
            ))
          ) : (
            <span
              style={{ fontSize: "1em", fontWeight: "700", opacity: "0.4" }}
            >
              Aucun évènement à venir
            </span>
          )}
        </Card>
      </BlockLayer>
    </PageLayer>
  );
};

export default Home;
