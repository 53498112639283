import { useAuth0 } from "@auth0/auth0-react";
import { IconLock, IconLogout2 } from "@tabler/icons-react";
import { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { displayedTabs, isDev, isQa } from "../../environnment";
import logo from "../../images/logo-dark.png";
import { useProfile } from "../../providers/ProfileProvider";
import { filterAllowedTabs } from "../../shared/others/filterAllowedTabs";
import { Tab } from "../../types/tab";
import EnvironmentBadge from "./EnvironmentBadge";
import TabIcon from "./TabIcon";
import TabsGroups from "./TabsGroup";

const Sidebar = (): ReactElement => {
  const [activeTab, setActiveTab] = useState(window.location.pathname);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth0();
  const { roles } = useProfile();

  useEffect(() => {
    const tab: Tab | undefined = displayedTabs.find((tab: Tab) => {
      return tab.path === window.location.pathname;
    });

    document.title = `my.socraft - ${tab?.name ?? "Annuaire"}`;

    if (tab) {
      setActiveTab(tab.path);
      navigate(tab.path);
    }
  }, [navigate]);

  useEffect(
    () => setActiveTab("/" + location.pathname.split("/")[1]),
    [location],
  );

  const handleTabClick = (tab: Tab): void => {
    document.title = `my.socraft - ${tab.name}`;

    setActiveTab(tab.path);
    setIsOpen(false);

    navigate(tab.path);
  };

  const handleLogout = async (): Promise<void> =>
    await logout({ logoutParams: { returnTo: window.location.origin } });

  return (
    <>
      <label className="hamburger">
        <input
          type="checkbox"
          checked={isOpen}
          onChange={() => setIsOpen(!isOpen)}
        />
        <svg viewBox="0 0 32 32">
          <path
            className="line line-top-bottom"
            d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
          ></path>
          <path className="line" d="M7 16 27 16"></path>
        </svg>
      </label>
      <aside className={`${isOpen ? "sidebar open" : "sidebar"}`}>
        <header>
          <img
            onClick={() => (window.location.href = "")}
            className="logo medium"
            alt="my.socraft"
            src={logo}
          />
        </header>
        <nav>
          {displayedTabs
            .sort((a, b) => a.index - b.index)
            .map((tab: Tab, key: any) => {
              const isLocked = !filterAllowedTabs(roles, displayedTabs).some(
                (t) => t.name === tab.name,
              );

              if (isLocked && tab.hideIfRoleIsMissing) {
                return <div style={{ display: "none" }} key={key}></div>;
              } else {
                return (
                  <button
                    key={key}
                    className={`navigation-tab ${activeTab === tab.path && "current"}`}
                    onClick={() => handleTabClick(tab)}
                  >
                    <TabIcon name={tab.name} />
                    {tab.name} {isLocked && <IconLock size={15} />}
                  </button>
                );
              }
            })}
          <TabsGroups title="Outils">
            <button
              className="navigation-tab"
              onClick={() => window.open("https://socraft.slack.com/")}
            >
              Slack
            </button>
            <button
              className="navigation-tab"
              onClick={() => window.open("https://socraft.ch/blog")}
            >
              Blog
            </button>
          </TabsGroups>
          <button onClick={handleLogout} className="navigation-tab">
            <IconLogout2 size={30} />
            Déconnexion
          </button>
          {(isDev || isQa) && <EnvironmentBadge />}
        </nav>
      </aside>
    </>
  );
};

export default Sidebar;
