import tabs from "./static/others/navigationTabs.json";
import { Tab } from "./types/tab";

const tabsArray: Tab[] = Object.values(tabs);

export const isLocal: boolean = window.location.hostname === "localhost";
export const isDev: boolean =
  isLocal || window.location.hostname === "dev.my.socraft.ch";
export const isQa: boolean = window.location.hostname === "qa.my.socraft.ch";
export const isProd: boolean = window.location.hostname === "my.socraft.ch";

export const socraftApiBaseUrl = isLocal ? "http://localhost:8000/api" : `/api`;

const productionTabs: string[] = [
  "Accueil",
  "Coworking",
  "Profil",
  "Annuaire",
  "Comptes",
  "Nouveautés",
];
const localTabs: string[] = [
  "Accueil",
  "Coworking",
  "Profil",
  "Annuaire",
  "Comptes",
  "Nouveautés",
];

export const displayedTabs: Tab[] = [];

if (isDev || isQa) {
  localTabs.forEach((tabName: string) => {
    const localTab: Tab | undefined = tabsArray.find(
      (tab) => tab.name === tabName,
    );
    localTab && displayedTabs.push(localTab);
  });
} else {
  productionTabs.forEach((tabName: string) => {
    const productionTab: Tab | undefined = tabsArray.find(
      (tab) => tab.name === tabName,
    );
    productionTab && displayedTabs.push(productionTab);
  });
}

export const defaultImageUrl =
  "https://avatars.slack-edge.com/2023-02-01/4735569099092_250e4584903fddadc1ac_132.png";
