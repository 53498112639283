import {
  IconAt,
  IconInfoSquareRounded,
  IconPlus,
  IconSettings,
  IconUser,
} from "@tabler/icons-react";
import { ReactElement, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Card from "../components/common/Card";
import CardLoading from "../components/common/CardLoading";
import Date from "../components/common/Date";
import { defaultImageUrl } from "../environnment";
import BlockLayer from "../layers/BlockLayer";
import PageLayer from "../layers/PageLayer";
import { useDirectory } from "../providers/DirectoryProvider";
import { useHttp } from "../providers/HttpProvider";
import toast from "../shared/popups/toast";
import { checkCrafterAccountValues } from "../shared/profile/crafterAccountCreationChecks";
import { Role } from "../types/profile";

const Accounts = (): ReactElement => {
  const [file, setFile] = useState<File>();
  const [activeRoles, setActiveRoles] = useState<Role[]>([]);
  const [validRoles, setValidRoles] = useState<Role[]>([]);
  const [dragging, setDragging] = useState<boolean>(false);
  const [newProfilePictureUrl, setNewProfilePictureUrl] = useState<string>();
  const [changesHaveBeenMade, setChangesHaveBeenMade] =
    useState<boolean>(false);
  const [
    displayGoogleWorkspaceAccountCreationInput,
    setDisplayGoogleWorkspaceAccountCreationInput,
  ] = useState<boolean>(false);
  const [createGoogleWorkspaceAccount, setCreateGoogleWorkspaceAccount] =
    useState<boolean>();
  const [googleWorkspaceAccountEmail, setGoogleWorkspaceAccountEmail] =
    useState<string>();
  const [firstname, setFirstname] = useState<string>();
  const [lastname, setLastname] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { crafters, setCrafters } = useDirectory();
  const { loading: httpLoading, get, post, put } = useHttp();

  useEffect(() => {
    const initValidRoles = async (): Promise<void> => {
      try {
        const result = await get("/admin/roles");
        setValidRoles(result.roles);
      } catch (error: any) {
        void toast(
          "error",
          "Oops ! La récuperation de la liste des rôles dans Auth0 a échoué. Regardez dans la console pour plus de détails",
        );
      }
    };

    !httpLoading && void initValidRoles();
  }, [httpLoading, get]);

  useEffect(() => {
    if (activeRoles.some((r) => r.name === "admin" || r.name === "bizdev")) {
      setDisplayGoogleWorkspaceAccountCreationInput(true);

      if (createGoogleWorkspaceAccount === undefined) {
        setCreateGoogleWorkspaceAccount(true);
      }
    } else {
      setDisplayGoogleWorkspaceAccountCreationInput(false);
      setCreateGoogleWorkspaceAccount(undefined);
    }
  }, [activeRoles, createGoogleWorkspaceAccount]);

  useEffect(() => {
    if ((firstname ?? "").length || (lastname ?? "").length) {
      setGoogleWorkspaceAccountEmail(
        `${firstname?.toLowerCase() ?? ""}.${lastname?.toLowerCase() ?? ""}`,
      );
    } else {
      setGoogleWorkspaceAccountEmail(undefined);
    }
  }, [firstname, lastname]);

  const handleRolesChanges = (role: Role, checked: boolean): void => {
    const roles: Role[] = [...activeRoles];

    if (checked) {
      roles.push(role);
    } else {
      const roleIndex = activeRoles.findIndex((r) => role.id === r.id);
      roles.splice(roleIndex, 1);
    }

    setActiveRoles(roles);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const file = e.dataTransfer.files[0];

    if (file) {
      setNewProfilePictureUrl(URL.createObjectURL(file));
      setFile(file);
    }
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      const bufferUrl = URL.createObjectURL(file);
      setFile(file);
      setNewProfilePictureUrl(bufferUrl);
    }
  };

  const handleDragZoneHover = (e: any, dragging: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(dragging);
  };

  const handleAccountCreation = async (): Promise<void> => {
    if (firstname && lastname && email) {
      const workspaceAccountEmail = `${googleWorkspaceAccountEmail ?? "@@"}@socraft.ch`;

      const valuesCheck = checkCrafterAccountValues(
        firstname,
        lastname,
        email,
        createGoogleWorkspaceAccount ?? false,
        workspaceAccountEmail,
      );

      if (!valuesCheck.isValid) {
        void toast("error", valuesCheck.message ?? "Une erreur est survenue");
        return;
      }

      if (!activeRoles.length) {
        void toast("error", "Vous devez spécifier au moins un rôle");
        return;
      }

      try {
        setLoading(true);
        const result = await post("/admin/accounts", {
          firstname: firstname,
          lastname: lastname,
          email: email,
          roles: activeRoles,
          shouldCreateAGoogleWorkspaceAccount:
            createGoogleWorkspaceAccount ?? false,
          googleWorkspaceAccountEmail: createGoogleWorkspaceAccount
            ? workspaceAccountEmail
            : undefined,
          profilePicture: defaultImageUrl,
        });

        if (file) {
          const profilePictureUpload = await put(
            `/profiles/${email}/profilePicture`,
            file,
          );
          const updatedProfile = profilePictureUpload.updatedProfile;

          result.createdProfile = updatedProfile;
        }

        const updatedCraftersList = crafters;
        updatedCraftersList?.push(result.createdProfile);

        setCrafters(updatedCraftersList ?? []);

        resetForm();
        void toast("success", "Le compte a été créé !");
      } catch (error: any) {
        void toast("error", error.response.data.error);
      }

      setLoading(false);
    }
  };

  const resetForm = (): void => {
    setNewProfilePictureUrl(undefined);
    setFile(undefined);
    setFirstname("");
    setLastname("");
    setEmail("");
    setActiveRoles([]);
    setDisplayGoogleWorkspaceAccountCreationInput(false);
    setGoogleWorkspaceAccountEmail(undefined);
    setCreateGoogleWorkspaceAccount(undefined);
  };

  return (
    <PageLayer>
      <header>
        <div className="icon">
          <IconSettings size={55} />
        </div>
        <div className="text">
          <h1 className="page-title">Comptes</h1>
          <Date />
        </div>
      </header>
      <BlockLayer>
        <Card>
          {loading && <CardLoading />}
          <div className="card-title">
            <h2>Créer un compte</h2>
          </div>
          <div className="input-group">
            <IconUser />
            <input
              type="text"
              placeholder="Prénom"
              value={firstname ?? ""}
              onChange={(e) => {
                setChangesHaveBeenMade(true);
                setFirstname(e.target.value);
              }}
            />
          </div>
          <div className="input-group">
            <IconUser />
            <input
              type="text"
              placeholder="Nom"
              value={lastname ?? ""}
              onChange={(e) => {
                setChangesHaveBeenMade(true);
                setLastname(e.target.value);
              }}
            />
          </div>
          <div className="input-group">
            <IconAt />
            <input
              type="email"
              placeholder="Adresse mail"
              value={email ?? ""}
              onChange={(e) => {
                setChangesHaveBeenMade(true);
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="roles">
            {validRoles.map((role) => (
              <div key={role.id}>
                <span className="role bordered">
                  <div className="cntr">
                    <input
                      type="checkbox"
                      id={`cbx-${role.id}`}
                      className="hidden-xs-up cbx-x"
                      onChange={(e) =>
                        handleRolesChanges(role, e.target.checked)
                      }
                      checked={activeRoles.some(
                        (activeRole) => role.id === activeRole.id,
                      )}
                    />
                    <label htmlFor={`cbx-${role.id}`} className="cbx"></label>
                  </div>
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={`cbx-${role.id}`}
                  >
                    {role.name}
                  </label>
                  <IconInfoSquareRounded data-tooltip-id={role.id} />
                </span>
                <Tooltip id={role.id}>{role.description}</Tooltip>
              </div>
            ))}
          </div>
          {displayGoogleWorkspaceAccountCreationInput && (
            <div className="google-workspace-account-creation">
              <div
                style={{ display: "flex", alignItems: "center", gap: "13px" }}
              >
                <div className="cntr">
                  <input
                    type="checkbox"
                    id="google-workspace-account-creation"
                    className="hidden-xs-up cbx-x"
                    onChange={(e) =>
                      setCreateGoogleWorkspaceAccount(e.target.checked)
                    }
                    checked={createGoogleWorkspaceAccount}
                  />
                  <label
                    htmlFor="google-workspace-account-creation"
                    className="cbx"
                  ></label>
                </div>
                <label
                  htmlFor="google-workspace-account-creation"
                  style={{ cursor: "pointer" }}
                >
                  Créer un compte Google Workspace
                </label>
              </div>
              {createGoogleWorkspaceAccount && (
                <div className="input-group">
                  <IconAt />
                  <input
                    type="text"
                    placeholder="prénom.nom"
                    value={googleWorkspaceAccountEmail ?? ""}
                    onChange={(e) =>
                      setGoogleWorkspaceAccountEmail(e.target.value)
                    }
                  />
                  <span className="bold">@socraft.ch</span>
                </div>
              )}
              <br />
            </div>
          )}
          <h3>Photo de profil</h3>
          <input
            type="file"
            style={{ display: "none" }}
            id="file"
            onChange={handleFileChange}
          />
          <label
            onDragEnter={(e) => handleDragZoneHover(e, true)}
            onDragLeave={(e) => handleDragZoneHover(e, false)}
            onDragOver={(e) => handleDragZoneHover(e, true)}
            onDrop={handleDrop}
            className={
              dragging
                ? "profile-picture-uploader w-full dragging"
                : "profile-picture-uploader w-full"
            }
            htmlFor="file"
          >
            {newProfilePictureUrl ? (
              <img src={newProfilePictureUrl} alt="new" />
            ) : (
              <div className="empty-new-profile-picture"></div>
            )}
          </label>
          <div className="actions">
            <button
              disabled={!changesHaveBeenMade}
              onClick={handleAccountCreation}
              className="primary"
            >
              Créer le compte
              <IconPlus />
            </button>
          </div>
        </Card>
      </BlockLayer>
    </PageLayer>
  );
};

export default Accounts;
