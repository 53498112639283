import { IconCheck, IconPlus, IconSettings } from "@tabler/icons-react";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import toast from "../../shared/popups/toast";
import { CrafterElementProps } from "../../types/directory";

const Crafter = ({ crafter, canEdit }: CrafterElementProps): ReactElement => {
  const navigate = useNavigate();

  const handleShowMore = (): string =>
    (window.location.href = `https://socraft.community/profile/${crafter.shortId}`);

  const handleEditCrafter = (): void => navigate(`/directory/${crafter.email}`);

  const handleCopyProfileId = async (): Promise<void> => {
    if (crafter.id) {
      await navigator.clipboard.writeText(crafter.id);
      void toast("success", "ID du crafter copiée");
    } else {
      void toast(
        "error",
        "Oups ! Il y a visiblement un problème avec l'ID de ce crafter",
      );
    }
  };

  return (
    <div className="crafter-card">
      {crafter.openToWork && (
        <div className="open-to-work-badge">
          Open To Work <IconCheck />
        </div>
      )}
      <div className="header">
        <img
          className="avatar"
          alt="Crafter avatar"
          loading="lazy"
          style={{
            cursor: canEdit ? "pointer" : "default",
          }}
          onClick={() => {
            canEdit && handleCopyProfileId();
          }}
          src={crafter.profilePicture}
        />
        <div className="infos">
          <h2 className="fullname">
            {crafter.firstname}{" "}
            <span className="yellow">{crafter.lastname}</span>
          </h2>
          <div className="contact">
            {crafter.email?.length && (
              <span className="info">{crafter.email}</span>
            )}
            {crafter.phone?.length && (
              <span className="info">{crafter.phone}</span>
            )}
          </div>
        </div>
      </div>
      <div className="actions">
        {canEdit && (
          <button onClick={handleEditCrafter} className="secondary">
            Modifier
            <IconSettings />
          </button>
        )}
        <button onClick={handleShowMore} className="primary">
          Voir plus
          <IconPlus />
        </button>
      </div>
    </div>
  );
};

export default Crafter;
