import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { formatToBirthday } from "../shared/directory/formatToBirthday";
import { filterAllowedTabs } from "../shared/others/filterAllowedTabs";
import tabs from "../static/others/navigationTabs.json";
import {
  DirectoryContextProps,
  DirectoryProviderProps,
} from "../types/directory";
import { Profile } from "../types/profile";
import { useHttp } from "./HttpProvider";
import { useProfile } from "./ProfileProvider";

export const DirectoryContext = createContext(undefined);

export const DirectoryProvider = ({
  children,
}: DirectoryProviderProps): ReactElement => {
  const [crafters, setCrafters] = useState<Profile[] | null>(null);
  const [birthdays, setBirthdays] = useState<string[]>([]);
  const { roles } = useProfile();
  const { loading, get, post } = useHttp();

  useEffect(() => {
    const initDirectory = async (): Promise<void> => {
      try {
        const birthdaysList: string[] = [];

        const getDirectoryResult = await get("/profiles");
        setCrafters(getDirectoryResult.profiles);

        getDirectoryResult.profiles.forEach((crafter: Profile) => {
          const crafterBirhtday = formatToBirthday(crafter?.birth_date ?? "");
          const today = formatToBirthday(new Date());

          if (
            today.day === crafterBirhtday.day &&
            today.month === crafterBirhtday.month
          ) {
            birthdaysList.push(`${crafter.firstname} ${crafter.lastname}`);
          }
        });
        setBirthdays(birthdaysList);
      } catch (error: any) {
        throw new Error(error);
      }
    };

    roles &&
      !loading &&
      filterAllowedTabs(roles, tabs).some((tab) => tab.name === "Annuaire") &&
      initDirectory();
  }, [loading, roles, get]);

  const editCrafterProfile = async (
    updatedProfile: Profile,
    email: string,
  ): Promise<Profile[]> => {
    try {
      if (crafters) {
        await post(`/profiles/${email}/update`, updatedProfile);

        const updatedCraftersList: Profile[] = [...crafters];
        const updatedCrafterIndex = crafters.findIndex(
          (crafter) => crafter.email === email,
        );
        updatedCraftersList[updatedCrafterIndex] = updatedProfile;

        setCrafters(updatedCraftersList);
        return updatedCraftersList;
      } else {
        return [];
      }
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const directoryContextValue: any = {
    crafters,
    birthdays,
    setCrafters,
    editCrafterProfile,
  };

  return (
    <DirectoryContext.Provider value={directoryContextValue}>
      {children}
    </DirectoryContext.Provider>
  );
};

export const useDirectory = (): DirectoryContextProps => {
  const directoryContext = useContext<any>(DirectoryContext);

  if (!directoryContext) {
    throw new Error("useDirectory must be used within a DirectoryProvider");
  }

  return directoryContext;
};
